import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import RoundupList from "../components/RoundupList";
import Meta from "../components/Meta";

const RecipeRoundup = () => {
    const { REACT_APP_API_URL } = process.env;
    const API_URL = REACT_APP_API_URL+`/pages/39?all=1&getroundup=1`;
    const [posts, setPosts] = useState([])

    const fetchData = () => {
        fetch(`${API_URL}`)
          .then(response => {
            return response.json()
          })
          .then(data => {
            setPosts(data)
          })
    }
    
    useEffect(() => {
        fetchData()
      }, [])
    return (

        <>
            {posts.length < 1 ? <div className="loading-wrap"><div className="loader"></div></div> : <>
                <Meta yoast={posts.yoast_head_json ? posts.yoast_head_json : ''} />
                <div className="mid-content">
                    <div className="mid-content-inner">
                        <section className="hero-sec">
                            <div className="page-title mb-5">
                                <div className="container">
                                <h1>{posts.title ? posts.title.rendered : ''}</h1>
                                <span dangerouslySetInnerHTML={{__html: posts.content.rendered}}></span>
                                </div>
                            </div>
                        </section>
                        <section className="home-sec-2 sec-blogs">
                            <div className="container-fluid ">
                                <div className="row">
                                {posts.recipe_roundup_list ?
                                    Object.values(posts.recipe_roundup_list).map((roundup, index) => (
                                        <div className="col-xl-3 col-lg-4 col-md-6"  key={index}>
                                            <RoundupList roundup={roundup} key={index}/>
                                        </div>
                                    ))
                                    
                                : '' }
                                </div>
                                
                            </div>
                        </section>
                    </div>
                </div>
            </>
            }
        </>
    );
  };
  
  export default RecipeRoundup;