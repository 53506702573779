import logo from "./../assets/images/BrandLogo-cropped.svg";
import cameraIcon2 from "../assets/images/dificulty.png";
import messageIcon from "../assets/images/search.png";
import deleteIcon from "../assets/images/Time-icon.png";

const images = {
  logo,
  cameraIcon2,
  messageIcon,
  deleteIcon,
  
};

export default images;