import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Meta from "../components/Meta";


const Blogs = () => {
    const [posts, setPosts] = useState([])
    const { REACT_APP_API_URL } = process.env;
    const API_URL = REACT_APP_API_URL+'/pages/266?all=1&getblog=1';

    const fetchData = () => {
        fetch(`${API_URL}`)
          .then(response => {
            return response.json()
          })
          .then(data => {
            setPosts(data)
          })
    }
    
    useEffect(() => {
        fetchData()
      }, [])
    return (
        <>
            <div className="mid-content">
                <div className="mid-content-inner">
                    {posts.length < 1 ? <div className="loading-wrap"><div className="loader"></div></div> : <>
                        <Meta yoast={posts.yoast_head_json ? posts.yoast_head_json : ''} />
                        <section className="home-sec-2 sec-blogs bloglist">
                        {posts.blog_list ?
                            <div className="container-fluid ">
                                <div className="row">
                                    {
                                        Object.values(posts.blog_list).map((blog, index) => (
                                            <div className="col-12 blog"  key={index}>
                                                <div className="blog-img">
                                                    <figure> 
                                                        <Link to={blog.slug ? blog.slug : ''} dangerouslySetInnerHTML={{__html: blog.image}}></Link> 
                                                    </figure>
                                                </div>
                                                <div className="blog-summary">
                                                    <div className="content">
                                                        <h4><Link to={blog.slug ? blog.slug : ''} className="color-black">{blog.title ? blog.title : ''}</Link></h4>
                                                        {blog.excerpt ?
                                                            <p dangerouslySetInnerHTML={{__html: blog.excerpt}}></p>
                                                        : ''}
                                                        <Link to={blog.slug ? blog.slug : ''} className="btn">Read More</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                        : '' }
                        </section>
                        </>
                    }
                </div>
            </div>
        </>
    );
  };
  
  export default Blogs;