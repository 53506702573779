import React from "react";
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import RecipesList from "../components/RecipesList";
import Meta from "../components/Meta";
import { Link } from "react-router-dom";

const RecipeType = () =>{
    const [posts, setPosts] = useState([])
    const { slug } = useParams();
    const { REACT_APP_API_URL } = process.env;
    const API_URL = REACT_APP_API_URL+`/recipe_type?slug=${ slug }&getrecipe=1`;
    
    const fetchData = () => {
        fetch(`${API_URL}`)
          .then(response => {
            return response.json()
          })
          .then(data => {
            setPosts(data[0])
          })
    }
    
    useEffect(() => {
        fetchData()
      }, [])
    
      return(
        <>
        {posts.length < 1 ? <div className="loading-wrap"><div className="loader"></div></div> : <>
        <Meta yoast={posts.yoast_head_json} />
            <div className="mid-content">
                <div className="mid-content-inner">
                    <section className="hero-sec">
                        <div className="featured-section">
                            <div className="container-fluid">
                                <div className="row align-items-center">
                                    <div className="col-md-5 col-lg-3 text-center">
                                        <div className="featured-img">
                                            <span>
                                                <img src={posts.acf.add_image.sizes.medium} alt={posts.acf.add_image.alt}/>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-lg-8">
                                        <div className="breadcrumb">
                                            <ul>
                                                <li><Link to="/">dadumtable</Link></li>
                                                <li><Link to="/recipe">Recipe</Link></li>
                                                <li>{posts.name}</li>
                                            </ul>
                                        </div>
                                        <h1>{posts.name}</h1>
                                        <p>{posts.description}</p>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </section>
                    <section className="home-sec-2 sec-recipes">
                        <div className="container-fluid ">
                            <h2 className="mb-3">Top Rated {posts.name} Recipes</h2>
                            <div className="row">
                                {posts.recipe_list ? 
                                    Object.values(posts.recipe_list).map((recipe, index) => (
                                        <div className="col-xl-3 col-lg-4 col-md-6" key={index}>
                                            <RecipesList recipe={recipe}/>
                                        </div>
                                    ))
                                    
                                : <h3 className="mb-3">Coming Soon!</h3> }
                                
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
        }
        </>

      )
}

export default RecipeType;