import React from "react";
import { Helmet } from "react-helmet";
const Meta =({yoast}) => {
    return(
        <>
            <Helmet>
                <title>{yoast.title}</title>
                <meta name="description" content={yoast.description ? yoast.description : 'Simple Korean Recipes made by Simple Ingredients'} />
                <link rel="canonical" href={window.location.href} />
                <meta property="og:locale" content={yoast.og_locale} />
                <meta property="og:type" content={yoast.og_type} />
                <meta property="og:title" content={yoast.title} />
                <meta property="og:description" content={yoast.description ? yoast.description : 'Simple Korean Recipes made by Simple Ingredients'} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:site_name" content={yoast.og_site_name} />
                {yoast.hasOwnProperty('og_image') ? 
                    <meta property="og:image" content={yoast.og_image[0].url} />
                    : ''
                }
                {yoast.hasOwnProperty('og_image') ? 
                    <meta property="og:image:type" content={yoast.og_image[0].type} />
                    : ''
                }
                {yoast.hasOwnProperty('og_image') ? 
                    <meta property="og:image:width" content={yoast.og_image[0].width} />
                    : ''
                }
                {yoast.hasOwnProperty('og_image') ? 
                    <meta property="og:image:height" content={yoast.og_image[0].height} />
                    : ''
                }
            </Helmet>
        </>
    )
}

export default Meta