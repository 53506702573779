import "./assets/css/bootstrap.css";
import "./assets/css/main.css";
import "./assets/css/home.css";
import "./assets/css/responsive.css";
import "./assets/css/slick.css";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Recipe from "./pages/Recipe";
import RecipeAll from "./pages/RecipeAll";
import RecipeSingle from "./pages/RecipeSingle";
import Blogs from "./pages/Blog";
import KitchenBlog from "./pages/KitchenBlog";
import KitchenblogDetail from './pages/KitchenblogDetail';
import RecipeRoundup from "./pages/RecipeRoundup";
import RoundupSingle from './pages/RoundupSingle';
import RecipeType from './pages/RecipeType';
import BlogDetail from "./pages/BlogDetail";
import ContactUs from './pages/ContactUs';
import About from './pages/About';
import CommonPages from './pages/CommonPages';
import NoPage from "./pages/NoPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Header />}>
            <Route index element={<Home />} />
            <Route path="recipe" element={<Recipe />} />
            <Route path="recipes" element={<RecipeAll />} />
            <Route path="recipe/:slug" element={<RecipeSingle />} />
            <Route path="kitchen-guide" element={<KitchenBlog />} />
            <Route path="kitchen-guide/:slug" element={<KitchenblogDetail />} />
            <Route path="recipe-roundup" element={<RecipeRoundup />} />
            <Route path="recipe-roundup/:slug" element={<RoundupSingle />} />
            <Route path="recipe-type/:slug" element={<RecipeType />} />
            <Route path="blog" element={<Blogs />} />
            <Route path="blog/:slug" element={<BlogDetail />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="about" element={<About />} />
            <Route path="page/:slug" element={<CommonPages />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
