import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import Meta from "../components/Meta";

const About = () =>{
    const { REACT_APP_API_URL } = process.env;
    const API_URL = REACT_APP_API_URL+'/pages/41?getyoutube=1';
    const [posts, setPosts] = useState([])

    const fetchData = () => {
        fetch(`${API_URL}`)
          .then(response => {
            return response.json()
          })
          .then(data => {
            setPosts(data)
          })
    }
    
    useEffect(() => {
        fetchData()
      }, [])

    return(

        <>
        {posts.length < 1 ? <div className="loading-wrap"><div className="loader"></div></div> : <>
        <Meta yoast={posts.yoast_head_json} />
            
            <div className="mid-content">
                <div className="mid-content-inner">
                    <section className="home-sec-2 sec-aboutvid">
                        <div className="container-fluid kt-tab-layout-inherit p-0">
                            <div className="row m-0">
                                <div className="col-md-7 align-self-center p-0">
                                    <div className="sec-1-content">
                                    <h1>{posts.title ? posts.title.rendered : ''}</h1>
                                    <p>{posts.acf ? posts.acf.short_description : ''}</p>
                                    <a className="btnemail" href={posts.acf ? 'mailto:'+posts.acf.contact_me : ''}>Conatct Me</a>
                                    </div>
                                </div>
                                <div className="col-md-5 p-0">
                                    <figure className="m-0">
                                    <video loop="1" autoPlay="1" muted="1">
                                        <source src={posts.acf ? posts.acf.add_video : ''} type="video/mp4"/> 
                                    </video>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="home-sec-2 about-mid-content text-center">
                        <div className="container-fluid kt-tab-layout-inherit">
                        {posts.content ?
                            <div dangerouslySetInnerHTML={{__html: posts.content.rendered}}/>
                        : ''}
                        </div>
                    </section>
                    
                    {posts.youtube_data ?
                        <section className="home-sec-2  social-media">
                            <div className="container-fluid kt-tab-layout-inherit">
                                <h2 className="mb-3">{posts.acf ? posts.acf.youtube_heading : ''} <FontAwesomeIcon icon={faExternalLink} /></h2>
                                <div className="sub-text">
                                    <p><span>{posts.acf ? posts.acf.youtube_sub_heading : ''}</span></p>
                                </div>
                                <div className="row">
                                {
                                    Object.values(posts.youtube_data).map((youtube, index) => (
                                        <div className="col-md-4 col-sm-6"  key={index}>
                                            <div className="inner-content">
                                            <Link to={youtube.youtube_link} target="_blank"><figure dangerouslySetInnerHTML={{__html: youtube.image}}></figure></Link>
                                            </div>
                                        </div>
                                    ))
                                }
                                </div>
                            </div>
                        </section>
                    : '' }

                </div>

            </div>
            </>
        }
        </>
    )
}

export default About