import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RoundupList from "../components/RoundupList";
import Meta from "../components/Meta";
import SocialLinks from "../components/SocialLinks";
import Sidebar from "../components/Sidebar"

const RoundupSingle = () =>{
    const [posts, setPosts] = useState([])
    const { slug } = useParams();
    const { REACT_APP_API_URL } = process.env;
    const API_URL = REACT_APP_API_URL+`/recipe_roundups?slug=${ slug }&getroundup=1&getsidebar=1`;
    
    const fetchData = () => {
        fetch(`${API_URL}`)
          .then(response => {
            return response.json()
          })
          .then(data => {
            setPosts(data[0])
          })
    }
    
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData()
      }, [slug])
    return(
        <>
            {posts.length < 1 ? <div className="loading-wrap"><div className="loader"></div></div> : <>
            <Meta yoast={posts.yoast_head_json} />
            <div className="mid-content">
                <div className="mid-content-inner">
                      <section className="content-page-with-sidebar">
                        <div className="container-fluid kt-tab-layout-inherit">
                            <div className="row">
                                <div className="col-lg-8 main-content">
                                {posts.title ?  
                                    <h1 dangerouslySetInnerHTML={{__html: posts.title.rendered}}></h1>
                                    : ''}
                                    {posts.fimg_url ?
                                      <div className="featured-img" dangerouslySetInnerHTML={{__html: posts.fimg_url}}></div>
                                    : ''}
                                    {posts.content ?
                                        <div dangerouslySetInnerHTML={{__html: posts.content.rendered}}/>
                                    : ''}

                                    {posts.roundup_recipes ? <>
                                        <h3>Recipes</h3>
                                        {
                                            Object.values(posts.roundup_recipes).map((recipe,index) => (
                                            <div className="recip-block"  key={index}>
                                                <div className="image-container">
                                                    <figure><Link to={recipe.slug} dangerouslySetInnerHTML={{__html: recipe.recipe_image}}></Link></figure>
                                                </div>
                                                <div className="recip-summary">
                                                    <div className="key-categories">{
                                                        recipe.recipe_keys.map((key, index) => (
                                                            <span className="gf" key={index}>{key}</span>
                                                        ))
                                                    }
                                                    </div>
                                                    <h4><Link to={recipe.slug} className="color-black">{recipe.title}</Link></h4>
                                                    <p>{recipe.recipe_short_description}</p>

                                                    <Link to={recipe.slug} className="btn">Make the Recipe</Link>

                                                </div>
                                            </div>
                                            ))
                                        }
                                        </>
                                    : ''}
                                </div>
                                <div className="col-lg-4 sidebar">
                                  <div className="bloger-block">
                                  {posts.about_sidebar ?
                                        <div className="bloger-block" dangerouslySetInnerHTML={{__html: posts.about_sidebar}}/>
                                    : ''}
                                      <div className="social-links">
                                          <SocialLinks />
                                      </div>
                                    </div>
                                    <div className="recent-post">
                                        <h3 className="mb-3">Recent Recipe Roundups</h3>
                                        <div className="recipes-slider relatedblog">

                                        {posts.recipe_roundup_list ? 
                                                Object.values(posts.recipe_roundup_list).map((roundup, index) => (
                                                    <div className="item text-center" key={index}>
                                                        <RoundupList roundup={roundup} />
                                                    </div>
                                                ))
                                                
                                            : '' }

                                        </div>
                                    </div>
                                    <div className="sidenews"><Sidebar /></div>
                                </div>
                            </div>
                        </div>
                      </section>
                </div>
            </div>
            </>
            }
        </>
    )

}

export default RoundupSingle;