import React from "react";
import { Link } from "react-router-dom";

const RoundupList = ( {roundup} ) =>{
    return(
            <div className="inner-content text-center ">
                <figure><Link to={roundup.slug} dangerouslySetInnerHTML={{__html: roundup.image}}></Link></figure>
                <div className="key-categories"> 
                {
                        (roundup.recipe_roundups_keys).map((recipekey,index) => ( 
                            <span className="gf" key={index}>{recipekey}</span> 
                        ))
                    }
                </div>
                <h4><Link to={roundup.slug} className="color-black">{roundup.title}</Link></h4>
            </div>
    )
}

export default RoundupList;