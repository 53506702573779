import React from "react";
import { useState, useEffect } from "react";
import BlogList from "../components/BlogList";
import { useParams } from 'react-router-dom';
import Meta from "../components/Meta";
import SocialLinks from "../components/SocialLinks";
import Sidebar from "../components/Sidebar";
const BlogDetail = (props) => {

  const [posts, setPosts] = useState([])
  const { slug } = useParams();
  const { REACT_APP_API_URL } = process.env;
  const API_URL = REACT_APP_API_URL+`/posts?slug=${ slug }&getblog=1&getsidebar=1`;
  
  const fetchData = () => {
      fetch(`${API_URL}`)
        .then(response => {
          return response.json()
        })
        .then(data => {
          setPosts(data[0])
        })
  }
  
  useEffect(() => {
      window.scrollTo(0, 0);
      fetchData()
    }, [slug])
    return(
        <>
          <div className="mid-content">
                <div className="mid-content-inner">
                    {posts.length < 0 ? <div className="loading-wrap"><div className="loader"></div></div> : <>
                    <Meta yoast={posts.yoast_head_json ? posts.yoast_head_json: ''} />
                      <section className="content-page-with-sidebar">
                        <div className="container-fluid kt-tab-layout-inherit">
                            <div className="row">
                                <div className="col-lg-8 main-content">
                                    <h1>{posts.title ? posts.title.rendered : ''}</h1>
                                    <div className="featured-img" dangerouslySetInnerHTML={{__html: posts.fimg_url}}>
                          
                                        
                                    </div>
                                    {posts.content ?
                                        <div dangerouslySetInnerHTML={{__html: posts.content.rendered}}/>
                                    : ''}
                                </div>
                                <div className="col-lg-4 sidebar">
                                  <div className="bloger-block">
                                  {posts.about_sidebar ?
                                        <div className="bloger-block" dangerouslySetInnerHTML={{__html: posts.about_sidebar}}/>
                                    : ''}
                                      <div className="social-links">
                                          <SocialLinks />
                                      </div>
                                    </div>
                                    <div className="recent-post">
                                        <h3 className="mb-3">Recent Blogs</h3>
                                        <div className="recipes-slider relatedblog">

                                        {posts.blog_list ? 
                                                Object.values(posts.blog_list).map((blog, index) => (
                                                    <div className="item text-center"  key={index}>
                                                        <BlogList blog={blog}  key={index}/>
                                                    </div>
                                                ))
                                                
                                            : '' }

                                        </div>
                                    </div>
                                    <div className="sidenews"><Sidebar /></div>
                                </div>
                            </div>
                        </div>
                      </section>
                    </>
                    }
                </div>
            </div>
        </>
    )
    
  };
  
  export default BlogDetail;