import React from "react";
import { Helmet } from "react-helmet";
import Images from "../themes/Images"
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import RecipesList from "../components/RecipesList";
import RoundupList from "../components/RoundupList";
import BlogList from "../components/BlogList";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import Meta from "../components/Meta";


const Home = () => {
    const { REACT_APP_API_URL } = process.env;
    const API_URL = REACT_APP_API_URL+`/pages/37?getrecipe=1&getroundup=1&getblog=1&getyoutube=1`;
    const [posts, setPosts] = useState([])

    const fetchData = () => {
        fetch(`${API_URL}`)
          .then(response => {
            return response.json()
          })
          .then(data => {
            setPosts(data)
          })
    }
    
    useEffect(() => {
        fetchData()
      }, [])
    return(
        <>
        <div className="mid-content">
        <div className="mid-content-inner">
        {posts.length < 1 ? <div className="loading-wrap"><div className="loader"></div></div> : <>
        <Meta yoast={posts.yoast_head_json} />
        <Helmet>
            <script async data-uid="38115e0e46" src="https://wondrous-knitter-1855.ck.page/38115e0e46/index.js"></script>
        </Helmet>
            <section className="simple-recipes">
                <h1 className="text-center mb-5">{posts.acf ? posts.acf.heading : ''}</h1>
                <div className="container-fluid">
                    <div className="row">
                            {posts.recipe_type_home ? 
                                Object.values(posts.recipe_type_home.main_recipe).map((mainrecipe, index) => (  
                                    <div className="col-lg-3 col-sm-6" key={index}>
                                        <div className="simple-recipes-inner"> 
                                            <Link to={mainrecipe.slug}>
                                                <figure><img src={mainrecipe.image.sizes.medium_large} alt={mainrecipe.image.alt}/></figure>
                                                <h5>{mainrecipe.name}</h5>
                                            </Link> 
                                        </div>
                                    </div>
                                ))
                                
                            : '' }  
                    </div>
                    <div className="bottom-row row">
                    {posts.recipe_type_home ? 
                        Object.values(posts.recipe_type_home.secondary_recipe).map((secondaryrecipe, index) => (
                            <div className="recipes-bottom-item" key={index}>
                                <Link to={secondaryrecipe.slug}>
                                    <figure><img src={secondaryrecipe.image.sizes.thumbnail} alt={secondaryrecipe.image.alt}/></figure>
                                    <h6>{secondaryrecipe.name}</h6>
                                </Link>
                            </div>
                        ))
                        
                    : '' }
                    </div>
                </div>
            </section>
            
            <section className="home-sec-2 sec-recipes">
                <div className="container-fluid ">
                    <h2 className="mb-3">{posts.acf ? posts.acf.recent_recipes.heading : ''}</h2>
                    <div className="sub-text">
                        <p>{posts.acf ? posts.acf.recent_recipes.sub_heading : ''}</p> 
                        <span className="divider">|</span> 
                        <Link to="/recipes">{posts.acf ? posts.acf.recent_recipes.link_text : ''}</Link>
                    </div>
                    <div className="row">
                        {posts.recipe_list ? 
                            Object.values(posts.recipe_list).map((recipe, index) => (
                                <div className="col-xl-3 col-lg-4 col-md-6" key={index}>
                                    <RecipesList recipe={recipe} key={index}/>
                                </div>
                            ))
                            
                        : '' }
                        
                    </div>
                </div>
            </section>
            <section className="home-sec-2 sec-aboutvid">
                <div className="container-fluid p-0">
                    <div className="row m-0">
                        <div className="col-md-7 align-self-center p-0">
                            <div className="sec-1-content">
                                <h2>{posts.acf ? posts.acf.about_us.heading : ''}</h2>
                                <Link to="/about" className="btn">{posts.acf ? posts.acf.about_us.link_text : ''}</Link>
                            </div>
                        </div>
                        <div className="col-md-5 p-0">
                            <figure className="m-0">
                                <video loop="1" autoPlay="1" muted="1">
                                    <source src={posts.acf ? posts.acf.about_us.add_video : ''} type="video/mp4"/>
                                </video>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="home-sec-2 sec-recipes">
                <div className="container-fluid ">
                    <h2 className="mb-3">{posts.acf ? posts.acf.recipe_roundups.heading : ''}</h2>
                    <div className="sub-text">
                        <p>{posts.acf ? posts.acf.recent_recipes.sub_heading : ''}</p> 
                        <span className="divider">|</span> 
                        <Link to="/recipe-roundup">{posts.acf ? posts.acf.recipe_roundups.link_text : ''}</Link>
                    </div>
                    <div className="row">
                        {posts.recipe_roundup_list ? 
                            Object.values(posts.recipe_roundup_list).map((roundup, index) => (
                                <div className="col-xl-3 col-lg-4 col-md-6"  key={index}>
                                    <RoundupList roundup={roundup} key={index}/>
                                </div>
                            ))
                                
                        : '' }
                    </div>
                </div>
            </section>
            <section className="shop-sec p-0">
                <div className="container-fluid p-0">
                    <div className="shop-sec-inner">
                        <figure className="m-0"><img src={posts.acf ? posts.acf.shop_section.image : ''} alt="shop" /> </figure>
                        <div className="shop-sec-content">
                            <div className="inner-logo"><img src={Images.logo} alt="shop" /></div>
                            <h2>{posts.acf ? posts.acf.shop_section.heading : ''}</h2>
                            <p>{posts.acf ? posts.acf.shop_section.description : ''}</p> <a href="#">{posts.acf ? posts.acf.shop_section.button_text : ''}</a>
                        </div>
                    </div>
                </div>
            </section>
            {posts.blog_list ?
                <section className="home-sec-2 blog-sec">
                    <div className="container-fluid ">
                        <h2 className="mb-3">{posts.acf ? posts.acf.home_blog.heading : ''}</h2>
                        <div className="sub-text">
                            <p><span>{posts.acf ? posts.acf.home_blog.sub_heading : ''}</span></p>
                        </div>
                        <div className="row">
                        {
                            Object.values(posts.blog_list).map((blog, index) => (
                                <div className="col-md-4"  key={index}>
                                    <BlogList blog={blog} key={index}/>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                </section>
            : '' }

            {posts.youtube_data ?
                <section className="home-sec-2  social-media">
                    <div className="container-fluid ">
                        <h2 className="mb-3">{posts.acf ? posts.acf.home_youtube.heading : ''} <FontAwesomeIcon icon={faExternalLink} /></h2>
                        <div className="sub-text">
                            <p><span>{posts.acf ? posts.acf.home_youtube.sub_heading : ''}</span></p>
                        </div>
                        <div className="row">
                        {
                            Object.values(posts.youtube_data).map((youtube, index) => (
                                <div className="col-md-4 col-sm-6" key={index}>
                                    <div className="inner-content">
                                        <Link to={youtube.youtube_link} target="_blank"><figure dangerouslySetInnerHTML={{__html: youtube.image}}></figure></Link>
                                    </div>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                </section>
            : '' }
            </>
        }
        </div>
    </div>
    </>
    );


};

export default Home;