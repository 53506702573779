import React from "react";
import { Link } from "react-router-dom";
import Images from "../themes/Images"

const RecipesList = ( {recipe} ) =>{
    return(
            <>
            <div className="inner-content text-center ">
                {recipe.recipe_image ?
                    <figure><Link to={recipe.slug}  dangerouslySetInnerHTML={{__html: recipe.recipe_image}}>
                        </Link>
                    </figure>
                    : " "
                }
                {recipe.recipe_keys ?
                    <div className="key-categories">
                        {
                            (recipe.recipe_keys[0]).map((recipekey, index) => ( 
                                <span className="gf" key={index}>{recipekey}</span>
                            ))
                        }
                    </div>
                    : " "
                }
                <h4><Link to={recipe.slug ? recipe.slug : ''} className="color-black">{recipe.title}</Link></h4>
                <p className="resedcp">{recipe.recipe_short_description}</p>
                <div className="recipes-time-dificulty">
                    <div className="time-dificulty-item"><span className="icon"><img src={Images.cameraIcon2} alt="difficulty" /></span>
                        <div className="tab-name">
                            <h6>Difficulty</h6>
                            <p>{recipe.difficulty_level}</p>
                        </div>
                    </div>
                    <div className="time-dificulty-item"><span className="icon"><img src={Images.deleteIcon} alt="time-required" /></span>
                        <div className="tab-name">
                            <h6>Times</h6>
                            <p>{recipe.time_required}</p>
                        </div>
                    </div>
                </div>
            </div>
           
            </>
    )
}

export default RecipesList;