import React from "react";
import { useState, useEffect } from "react";
import RecipesList from "../components/RecipesList";
import { Link } from "react-router-dom";
import Meta from "../components/Meta";

const Recipe = () =>{
    const { REACT_APP_API_URL } = process.env;
    const API_URL = REACT_APP_API_URL+`/pages/195?getrecipe=1&getrecipetype=1`;
    const [posts, setPosts] = useState([])
    const fetchData = () => {
        fetch(`${API_URL}`)
          .then(response => {
            return response.json()
          })
          .then(data => {
            setPosts(data)
          })
    }
    
    useEffect(() => {
        fetchData()
      }, [])
    return(
        <>
            <div className="mid-content">
                <div className="mid-content-inner">
                    {posts.length < 1 ? <div className="loading-wrap"><div className="loader"></div></div> : <>
                        <Meta yoast={posts.yoast_head_json} />
                        <section className="hero-sec">
                            <div className="page-title">
                                <div className="container">
                                    <h1>{posts.title ? posts.title.rendered : ''}</h1>
                                    <span dangerouslySetInnerHTML={{__html: posts.content.rendered}}/> 
                                </div>
                            </div>
                        </section>
                        <section className="all-recipie sec-recipes">
                            <div className="container-fluid ">
                                <h2 className="mb-3">Latest Recipes</h2>
                                <div className="sub-text">
                                    <p>Straight from the lab</p> 
                                    <span className="divider">|</span> 
                                    <Link to="/recipes">View all recipes</Link>
                                </div>
                                <div className="row">
                                    {posts.recipe_list ? 
                                        Object.values(posts.recipe_list).map((recipe, index) => (
                                            <div className="col-xl-3 col-lg-4 col-md-6" key={index}>
                                                <RecipesList recipe={recipe} key={index}/>
                                            </div>
                                        ))
                                        
                                    : '' }
                                    
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 text-center my-3">
                                        <Link to="/recipes" className="cta-btn">View all recipes +</Link>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {posts.recipe_cat_type ? <>
                        <section className="recipes-category">
                            <div className="container">
                                <div className="row">
                                {Object.entries(posts.recipe_cat_type.cattype).map(([typename, cattype]) => (
                                    <>
                                    <div className="col-12 cate-title" key={typename}>
                                        <h3>{typename}</h3>
                                    </div>
                                    <div className="col-12">
                                        <ul className="cate-list">
                                            {Object.values(cattype).map((cat, index) => (
                                            <li key={index}>
                                                <Link to={'/'+cat.slug}>{cat.name}</Link>
                                            </li>
                                            ))
                                            }
                                        </ul>
                                    </div>
                                    </>
                                ))
                                }
                                </div>
                            </div>
                        </section>
                        </>
                        : '' } 
                    </>
                    }
                </div>
            </div>
        </>
    )
}

export default Recipe;