import React from "react";
import not_found from "../assets/images/page-not-found.jpg";
import { Link } from "react-router-dom";
const NoPage = () => {
    return (
      <>
      <section className="error-sec">
        <div className="error-container p-3">
          <figure><img src={not_found} alt="error"/></figure>
          <div className="error-block">
              <h1>404</h1>
              <h3>OOPS! PAGE NOT FOUND</h3>
              <p> Sorry, the page you're looking for doesn't exist.</p>
              <div className="btn-block"> 
                  <Link to="/" className="btn return-btn">Return Home</Link>
                </div>
          </div>
        </div>
      </section>
      </>
    );
  };
  
  export default NoPage;