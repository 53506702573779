import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faYoutube, faTiktok, faPinterestP } from '@fortawesome/free-brands-svg-icons'

const SocialLinks = () => {
    return(
        <>
            
            <ul>
                <li className="social-icon"><a target="_blank" href="https://www.youtube.com/channel/UCdjoIVDKPpLDgeE9n8JrpZQ"><FontAwesomeIcon icon={faYoutube} /></a></li>
                <li className="social-icon"><a target="_blank" href="https://www.instagram.com/dadumtable/"><FontAwesomeIcon icon={faInstagram} /></a></li>
                <li className="social-icon"><a target="_blank" href="https://www.tiktok.com/@dadumtable"><FontAwesomeIcon icon={faTiktok} /></a></li>
                <li className="social-icon"><a target="_blank" href="https://pin.it/28af4vU"><FontAwesomeIcon icon={faPinterestP} /></a></li>
            </ul>
            
        </>
    )
}

export default SocialLinks