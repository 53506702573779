import React from "react";
import { Link } from "react-router-dom";

const BlogList = ( {blog} ) =>{
    return(
            <Link to ={blog.slug}>
                <div className="inner-content">
                    <figure className="m-0" dangerouslySetInnerHTML={{__html: blog.image}}></figure>
                    <div className="blog-conetent">
                        <h4>{blog.title}</h4>
                        <p>{blog.excerpt}</p>
                    </div>
                </div>
            </Link>
    )
}

export default BlogList;