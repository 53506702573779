import React from "react";
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import Meta from "../components/Meta";
import SocialLinks from "../components/SocialLinks";
import Sidebar from "../components/Sidebar";
const KitchenblogDetail = () => {

  const [posts, setPosts] = useState([])
  const { slug } = useParams();
  const { REACT_APP_API_URL } = process.env;
  const API_URL = REACT_APP_API_URL+`/kitchen_guide?slug=${ slug }&getkitchen=1&getsidebar=1`;
  const fetchData = () => {
      fetch(`${API_URL}`)
        .then(response => {
          return response.json()
        })
        .then(data => {
          setPosts(data[0])
        })
  }
  
  useEffect(() => {
    window.scrollTo(0, 0);
      fetchData()
    }, [slug])
    return(
        <>
          <div className="mid-content">
                <div className="mid-content-inner">
                    {posts.length < 1 ? <div className="loading-wrap"><div className="loader"></div></div> : <>
                    <Meta yoast={posts.yoast_head_json} />
                      <section className="content-page-with-sidebar">
                        <div className="container-fluid kt-tab-layout-inherit">
                            <div className="row">
                                <div className="col-lg-8 main-content">
                                    <h1>{posts.title ? posts.title.rendered : ''}</h1>
                                    {posts.fimg_url ?
                                      <div className="featured-img" dangerouslySetInnerHTML={{__html: posts.fimg_url}}></div>
                                    : ''}
                                    {posts.content ?
                                        <div dangerouslySetInnerHTML={{__html: posts.content.rendered}}/>
                                    : ''}
                                </div>
                                <div className="col-lg-4 sidebar">
                                  <div className="bloger-block">
                                  {posts.about_sidebar ?
                                        <div className="bloger-block" dangerouslySetInnerHTML={{__html: posts.about_sidebar}}/>
                                    : ''}
                                      <div className="social-links">
                                          <SocialLinks />
                                      </div>
                                    </div>
                                    <div className="recent-post">
                                        <h3 className="mb-3">Recent Kitchen Guides</h3>
                                        <div className="recipes-slider relatedblog">

                                        {posts.kitchen_guide_list ? 
                                                Object.values(posts.kitchen_guide_list).map((kitchen_guide, index) => (
                                                    <div className="item text-center" key={index}>
                                                        <div className="inner-content text-center ">
                                                            <figure><Link to={kitchen_guide.slug} dangerouslySetInnerHTML={{__html: kitchen_guide.image}}></Link></figure>
                                                            <h4><Link to={kitchen_guide.slug} className="color-black">{kitchen_guide.title}</Link></h4>
                                                        </div>
                                                    </div>
                                                ))
                                                
                                            : '' }

                                        </div>
                                    </div>
                                    <div className="sidenews"><Sidebar /></div>
                                </div>
                            </div>
                        </div>
                      </section>
                    </>
                    }
                </div>
            </div>
        </>
    )
    
  };
  
  export default KitchenblogDetail;