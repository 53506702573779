import React from "react";
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Meta from "../components/Meta";
const CommonPages = () => {
    const { slug } = useParams();
    const { REACT_APP_API_URL } = process.env;
    const API_URL = REACT_APP_API_URL+`/pages?slug=${ slug }`;
    const [posts, setPosts] = useState([])

    const fetchData = () => {
        fetch(`${API_URL}`)
          .then(response => {
            return response.json()
          })
          .then(data => {
            setPosts(data[0])
          })
    }
    
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData()
      }, [])

    return (
        <>
        {posts.length < 1 ? <div className="loading-wrap"><div className=" loader"></div></div> : <>
            <Meta yoast={posts.yoast_head_json} />
            <div className="mid-content">
                <div className="mid-content-inner">
                    <div className="container">
                        <div className="row">
                        <section>
                            <h1>{posts.title ? posts.title.rendered : ''}</h1>
                            {posts.content ?
                                <div dangerouslySetInnerHTML={{__html: posts.content.rendered}}/>
                            : ''}
                        </section>
                        </div>
                    </div>
                </div>
            </div>
        </>
        }
        </>
    )
}

export default CommonPages;