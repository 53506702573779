import { React, useState, useEffect } from "react";
import RecipesList from "../components/RecipesList";
import Meta from "../components/Meta";

const RecipeAll = () =>{
    const { REACT_APP_API_URL } = process.env;
    const API_URL = REACT_APP_API_URL+`/pages/312?all=1&getrecipe=1&getrecipetype=1`;
    const [posts, setPosts] = useState([])
    const [q, setQ] = useState("")
    const [recipekey, setKey] = useState("")
    const [recipecat, setCat] = useState("")

    const fetchData = () => {
        
        fetch(`${API_URL}&s=${q}&recipes=${recipecat}&recipekey=${recipekey}`)
          .then(response => {
            return response.json()
          })
          .then(data => {
            setPosts(data)
          })
    }
    
    useEffect(() => {
        fetchData()
      }, [])
    return(
        <>
            <div className="mid-content">
                <div className="mid-content-inner">
                {posts.length < 1 ? <div className="loading-wrap"><div className="loader"></div></div> : <>
                    <Meta yoast={posts.yoast_head_json} />
                        <section className="all-recipie sec-recipes">
                            <div className="container-fluid">
                            <div className="container">
                                <h1 className="text-center">{posts.title ? posts.title.rendered : ''}</h1>
                                <span dangerouslySetInnerHTML={{__html: posts.content.rendered}}/> 
                            </div>
                                <div className="filter-tab mb-5">
                                    <div className="filter-seach filter-item"><input type="text" value={q} placeholder="Search by name" onChange={(e) => setQ(e.target.value)}/> </div>
                                    <div className="select-tab filter-item">
                                    {posts.recipe_cat_type ?
                                        <select name="recipetype" onChange={(e) => setCat(e.target.value)}>
                                            <option value="">By category</option>
                                            {Object.entries(posts.recipe_cat_type.cattype).map(([typename, cattype]) => (
                                                <>
                                                <optgroup label={typename}>
                                                {Object.values(cattype).map((cat, index) => (
                                                    <option value={cat.orgslug}> {cat.name}</option>
                                                    ))
                                                }
                                                </optgroup>
                                                </>
                                                ))
                                            }
                                        </select> 
                                    : '' }
                                    </div>
                                    <div className="select-tab filter-item"> 
                                    {posts.recipe_cat_type ?
                                        <select name="recipekey" onChange={(e) => setKey(e.target.value)}>
                                            <option value="">By recipes key</option>
                                            {Object.values(posts.recipe_cat_type.recipekey).map((recipekey) => (
                                                <>
                                                <option value={recipekey.slug}> {recipekey.name}</option>
                                                </>
                                                ))
                                            }
                                        </select> 
                                    : '' }
                                    </div> 
                                    <button onClick={() => fetchData(q)} className="filter-btn">filter</button>
                                </div>
                                <div className="row">
                                    {posts.recipe_list ? 
                                        Object.values(posts.recipe_list).map((recipe, index) => (
                                            <div className="col-xl-3 col-lg-4 col-md-6">
                                                <RecipesList recipe={recipe}/>
                                            </div>
                                        ))
                                        
                                    : <h3 className="text-center">No Recipe Found!</h3> }
                                    
                                </div>
                            </div>
                        </section>
                    </>
                    }
                </div>
            </div>
        </>
    )
}

export default RecipeAll;