import Images from '../themes/Images'
import { React, useState, useEffect, useRef } from 'react';
import { Outlet, Link } from "react-router-dom";
import SocialLinks from "../components/SocialLinks";
const Header = () => {
    const [navbarOpen, setNavbarOpen] = useState(false);
    const x = 0;
    const ref = useRef();
        useEffect(() => {
            const handler = (event) => {
            if (
                navbarOpen &&
                ref.current &&
                x &&
                !ref.current.contains(event.target)
            ) {
                setNavbarOpen(false);
            }
            };
            document.addEventListener('mousedown', handler);
            return () => {
                // Cleanup the event listener
                document.removeEventListener('mousedown', handler);
            };
    }, [navbarOpen]);
    return (
        <>
          <section className="header">
            <header>
                <div className="container-fluid">
                    <div className="navbar-inner menu-type-text">
                        <div className="navbar-center">
                            <div className="logo"><Link to="/"><img src={Images.logo} alt="dadum-table"/></Link></div>
                            <nav className="navbar navbar-expand-lg navbar-light"> 
                                <button onClick={() => setNavbarOpen((prev) => !prev)} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> 
                                    <span className="navbar-toggler-icon"></span> 
                                </button>
                                
                                    <div className={`navbar-collapse ${navbarOpen ? 'show-menu' : ''}`} id="navbarSupportedContent" ref={ref}>
                                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                            <li className="nav-item"><Link to="/recipe" onClick={() => setNavbarOpen(false)} className="nav-link">Recipes</Link> </li>
                                            <li className="nav-item"><Link to="/kitchen-guide" onClick={() => setNavbarOpen(false)} className="nav-link">Kitchen Guide</Link></li>
                                            <li className="nav-item"><Link to="/recipe-roundup" onClick={() => setNavbarOpen(false)} className="nav-link">Recipe Roundups</Link></li>
                                            <li className="nav-item"><Link to="/about" onClick={() => setNavbarOpen(false)} className="nav-link">About</Link></li>
                                            <li className="nav-item"><Link to="/blog" onClick={() => setNavbarOpen(false)} className="nav-link">Blogs</Link></li>
                                        </ul>
                                        <nav className="social-menu mobile-social">
                                            <SocialLinks/>
                                        </nav>
                                    </div>
                                    
                            </nav>
                            <div className="header-right-block">
                                <nav className="social-menu">
                                    <SocialLinks/>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </section>
    
          <Outlet />
        </>
      )
  };
  
  export default Header;