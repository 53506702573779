import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Meta from "../components/Meta";

const KitchenBlog = () => {
    const { REACT_APP_API_URL } = process.env;
    const API_URL = REACT_APP_API_URL+`/pages/43?all=1&getkitchen=1`;
    const [posts, setPosts] = useState([])

    const fetchData = () => {
        fetch(`${API_URL}`)
          .then(response => {
            return response.json()
          })
          .then(data => {
            setPosts(data)
          })
    }
    
    useEffect(() => {
        fetchData()
      }, [])
    return (

        <>
            {posts.length < 1 ? <div className="loading-wrap"><div className="loader"></div></div> : <>

                <Meta yoast={posts.yoast_head_json} />
                <div className="mid-content">
                    <div className="mid-content-inner">
                        <section className="hero-sec">
                            <div className="page-title mb-5">
                                <div className="container">
                                    <h1>{posts.title ? posts.title.rendered : ''}</h1>
                                    {posts.content ?
                                        <span dangerouslySetInnerHTML={{__html: posts.content.rendered}}/>
                                    : ''}
                                </div>
                            </div>
                        </section>
                        <section className="home-sec-2 sec-blogs">
                            <div className="container-fluid ">
                                <div className="row">
                                {posts.kitchen_guide_list ?
                                     Object.values(posts.kitchen_guide_list).map((kitchen_guide, index) => (
                                        <div className="col-xl-3 col-lg-4 col-md-6" key={index}>
                                            <div className="inner-content text-center ">
                                                <figure><Link to={kitchen_guide.slug} dangerouslySetInnerHTML={{__html: kitchen_guide.image}}></Link></figure>
                                                <h4><Link to={kitchen_guide.slug} className="color-black">{kitchen_guide.title}</Link></h4>
                                            </div>
                                        </div>
                                    ))
                                    : '' }
                                </div>
                                
                            </div>
                        </section>
                    </div>
                </div>
            </>
            }
        </>

    );
  };
  
  export default KitchenBlog;