import React from "react";
import { useState, useEffect } from "react";
import Meta from "../components/Meta";

const TOKEN = typeof window !== 'undefined' && window.btoa(`dasomkim:IQx(S#Nh&5Oo7hUY&WR67&IO`); // Convert Base64

const ContactUs = () =>{
    const { REACT_APP_API_URL } = process.env;
    const API_URL = REACT_APP_API_URL+'/pages/226';
    const [posts, setPosts] = useState([])
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState("");
    const [smessage, setSuccessm] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${TOKEN}`);

            var formdata = new FormData();
            formdata.append("yourname", name);
            formdata.append("email", email);
            formdata.append("subject", subject);
            formdata.append("message", message);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            const res = await fetch("https://kims.codeinclusive.com/wp-json/contact-form-7/v1/contact-forms/228/feedback", requestOptions)
            

          const resJson = await res.json();
          if (res.status === 200) {
            if(resJson.status == 'mail_sent'){
                setName("");
                setEmail("");
                setSubject("");
                setMessage("");
                setSuccessm('noerror');
            }else{
                setSuccessm('error');
            }
          }
          setSuccess(resJson.message);
        } catch (err) {
          console.log(err);
        }
    };

    const fetchData = () => {
        fetch(`${API_URL}`)
          .then(response => {
            return response.json()
          })
          .then(data => {
            setPosts(data)
          })
    }
    
    useEffect(() => {
        fetchData()
      }, [])

    return(
        <>
        {posts.length < 1 ? <div className="loading-wrap"><div className="loader"></div></div> : <>
            <Meta yoast={posts.yoast_head_json} />
            <div className="mid-content">
                <div className="mid-content-inner">
                    <section className="image-with-content contactform">
                        {posts.fimg_url ?
                            <div className="img-block">
                                <figure  dangerouslySetInnerHTML={{__html: posts.fimg_url}}></figure>
                            </div>
                        : ''}
                        <div className="content-block contact-block">
                            <div className="content">
                                <h2>Contact me</h2>
                                <form onSubmit={handleSubmit} className="contact-form">
                                    <div className="form-group">
                                        <label>Your name*</label>
                                        <input type="text" name="name" className="form-control" required value={name} onChange={(e) => setName(e.target.value)}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Your email*</label>
                                        <input type="email" name="email" className="form-control" required value={email} onChange={(e) => setEmail(e.target.value)}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Subject*</label>
                                        <input type="text" name="subject" className="form-control" required value={subject} onChange={(e) => setSubject(e.target.value)}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Your message</label>
                                        <textarea className="form-control" name="message" value={message} onChange={(e) => setMessage(e.target.value)}/>
                                    </div>
                                    <div className="form-submit">
                                        <input type="submit" className="btn" value="Submit"/>
                                    </div>
                                    <div className="message">{success ? <p className={smessage ? smessage : ''}>{success}</p> : null}</div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
        }
        </>
    )
}

export default ContactUs