import Images from '../themes/Images'
import { Link } from "react-router-dom"
import { useState } from 'react';
const Footer = () => {
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState("");
    const [message, setMessage] = useState("");
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccess('');
        setMessage('');
        try {
            var formdata = new FormData();
            formdata.append("email", email);
            formdata.append("api_key", "w-vt-HWomWVmszKatljLtQ");

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            const res = await fetch("https://api.convertkit.com/v3/forms/3655859/subscribe", requestOptions)
            const resJson = await res.json();
            if (resJson.message) {
                setMessage(resJson.message);
                setSuccess('error');
            }else{
                setEmail("");
                
                setSuccess('noerror');
                setMessage('Subscribed Successfuly!');
            }
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <>
        <section className="footer">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-5">
                    <div className="footer-left">
                        <div className="foot-logo"> <img src={Images.logo} alt="dadumtable"/> </div>
                    </div>
                </div>
                <div className="col-lg-7 footer-right">
                    <div className="row">
                        <div className="col-sm-3 col-6">
                            <h4>Site map</h4>
                            <ul>
                                
                                <li><Link to="/recipe">Recipes</Link></li>
                                <li><Link to="/kitchen-guide">Kitchen Guide</Link></li>
                                <li><Link to="/recipe-roundup">Recipe Roundups</Link></li>
                                <li><Link to="/about">About</Link></li>
                                <li><Link to="/blog">Blogs</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                                <li><Link to="/page/privacy-policy">Priv. Policy</Link></li>
                            </ul>
                        </div>
                        <div className="col-sm-3 col-6">
                            <h4>Social</h4>
                            <ul>
                                <li><a rel="noreferrer" target="_blank" href="https://www.instagram.com/dadumtable/">Instagram</a></li>
                                <li><a rel="noreferrer" target="_blank" href="https://www.tiktok.com/@dadumtable">Tiktok</a></li>
                                <li><a rel="noreferrer" target="_blank" href="https://www.youtube.com/channel/UCdjoIVDKPpLDgeE9n8JrpZQ">Youtube</a></li>
                                <li><a rel="noreferrer" target="_blank" href="https://pin.it/28af4vU">Pinterest</a></li>
                            </ul>
                        </div>
                        <div className="col-sm-6">
                            <div className="newsletter">
                                <h4>Newsletter</h4>
                                <p>Join my mailing list for more delicious recipes and stories.</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <input type="email" placeholder="E-Mail Address" required value={email} onChange={(e) => setEmail(e.target.value)}/>
                                        <button className="submit">JOIN</button>
                                    </div>
                                    <div className="message">{message ? <p className={success ? success : 'nm'}>{message}</p> : null}</div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <p>&copy; {(new Date().getFullYear())} dadumtable </p>
            </div>
        </div>
    </section>
    </>
    );
  };
  
  export default Footer;