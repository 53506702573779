import React from "react";
import { useState } from "react";

const Sidebar = ()=> {
  const [email, setEmail] = useState("");
  const [fname, setName] = useState("");
    const [success, setSuccess] = useState("");
    const [message, setMessage] = useState("");
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccess('');
        setMessage('');
        try {
            var formdata = new FormData();
            formdata.append("email", email);
            formdata.append("first_name", fname);
            formdata.append("api_key", "w-vt-HWomWVmszKatljLtQ");

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            const res = await fetch("https://api.convertkit.com/v3/forms/3627390/subscribe", requestOptions)
            const resJson = await res.json();
            if (resJson.message) {
                setMessage(resJson.message);
                setSuccess('error');
            }else{
                setEmail("");
                setName("");
                setSuccess('noerror');
                setMessage('Subscribed Successfuly!');
            }
        } catch (err) {
            console.log(err);
        }
    };
  return(
    <>
      <form onSubmit={handleSubmit} style={{backgroundColor: '#fef1e4', borderRadius: '6px'}} className="seva-form formkit-form" data-sv-form={3627390} data-uid="fe2bb1b97d" data-format="inline" data-version={5} min-width="400 500 600 700 800 900 1000 1100 1200 1300 1400 1500 1600 1700 1800 1900"><div style={{backgroundImage: 'url("https://embed.filekitcdn.com/e/2gd6jzftANK8cMwSNBfvz8/kRCMwRf5uSMQCcp2CdhmSu")', opacity: '0.2'}} className="formkit-background" />
        <div data-style="minimal"><div className="formkit-header" style={{color: '#080707', fontSize: '22px', fontWeight: 700}} data-element="header"><h2 style={{textAlign: 'center'}}>Welcome to Kim's Foodie Club</h2></div>
        <div className="formkit-subheader" style={{color: '#332e2e', fontSize: '15px'}} data-element="subheader">
        <p><strong>​<br />Thanks for visiting! </strong></p>
        <p><strong>Join my mailing list for more delicious recipes and stories.</strong></p>
        </div>
        <ul className="formkit-alert formkit-alert-error" data-element="errors" data-group="alert" />
        <div data-element="fields" data-stacked="true" className="seva-fields formkit-fields">
        <div className="formkit-field"><input type="text" className="formkit-input" aria-label="First Name" value={fname} onChange={(e) => setName(e.target.value)} style={{color: '#0b0a0a', borderColor: '#dde0e4', fontWeight: 700, backgroundColor: '#F6F8FA', borderRadius: '5px'}} required placeholder="First Name" /></div>
        <div className="formkit-field"><input type="email" className="formkit-input" name="email_address" value={email} onChange={(e) => setEmail(e.target.value)} style={{color: '#0b0a0a', borderColor: '#dde0e4', fontWeight: 700, backgroundColor: '#F6F8FA', borderRadius: '5px'}} aria-label="Email Address" placeholder="Email Address" required /></div>
        <button data-element="submit" className="formkit-submit formkit-submit" style={{color: '#ffffff', backgroundColor: '#0b0a0a', borderRadius: '3px', fontWeight: 700}}>
        <div className="formkit-spinner">
          <div />
              <div />
                <div /></div>
                <span className>Subscribe for MORE!</span></button>
              </div>
              <div className="formkit-guarantee" style={{color: '#4d4d4d', fontSize: '13px', fontWeight: 400}} data-element="guarantee">We won't send you spam. Unsubscribe at any time.
          </div>
          <div className="formkit-powered-by-convertkit-container"><a href="https://convertkit.com/features/forms?utm_campaign=poweredby&utm_content=form&utm_medium=referral&utm_source=dynamic" data-element="powered-by" className="formkit-powered-by-convertkit" data-variant="dark" target="_blank" rel="nofollow">Built with ConvertKit</a></div>
          <div className="message">{message ? <p className={success ? success : 'nm'}>{message}</p> : null}</div>
        </div>
        </form>
    </>
  )
    
}

export default Sidebar;